// from https://github.com/basecamp/trix/issues/566
export function getRangeOfElement(element) {
    const { editor } = element.closest("trix-editor");

    const domRange = document.createRange();
    domRange.selectNode(element);

    const locationRange = editor.selectionManager.createLocationRangeFromDOMRange(domRange);
    return editor.getDocument().rangeFromLocationRange(locationRange);
}

// loosely based off https://gist.github.com/javan/7b0c99f43e67080c2380e8d30707f773
export function findInDocument(editor, regex) {
    // expects exactly one capture group to work properly
    let results = [];
    let docString = editor.getDocument().toString();
    let match;
    while ((match = regex.exec(docString)) !== null) {
        let position = match.index;
        let captureGroup = match[1];
        let range = [position, position + match[0].length];
        results.push({
            captureGroup,
            range
        });
    }
    return results;
}