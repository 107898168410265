import "core-js/stable";
import "regenerator-runtime/runtime";
import 'bootstrap'
require("@rails/ujs").start();

const Turbolinks = require("turbolinks");
Turbolinks.start();

import './lib/iubenda-turbolinks'
import 'corejs-typeahead'
import 'trix/dist/trix'
import './lib/trix-turbolinks'
import './forms'