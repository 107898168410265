document.addEventListener("turbolinks:load", () => {
    const trixFields = document.body.querySelectorAll("[data-behavior='trix-editor']:not([data-trix='ready'])");
    if (trixFields.length > 0) {
        trixFields.forEach(function (trixField) {
            const fieldId = trixField.attributes["id"].value;
            const trixEditor = document.createElement("trix-editor");
            trixEditor.classList.add("trix-content");
            trixEditor.setAttribute("input", fieldId);
            if (trixField.dataset.toolbar === "minimal") {
                trixEditor.setAttribute("toolbar", "trix-toolbar-minimal");
            }
            const parentDiv = trixField.parentNode;
            parentDiv.insertBefore(trixEditor, trixField);
            trixField.dataset.trix = "ready";
        });
    }
});