export function exponentialBackoff(f, n = 0){
    // A simple exponential backoff function.
    // Gives up after 50 tries, each increasing the power of e by 1/2.

    if(n > 25){
        return;
    }
    let x = f();
    if(!x) {
        window.setTimeout(function(){exponentialBackoff(f, n+0.5)}, (Math.E**n)*100);
    }
}