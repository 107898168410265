import hyperform from 'hyperform'
import Modernizr from 'modernizr'
import SignaturePad from 'signature_pad'
import Trix from 'trix'
import {getRangeOfElement, findInDocument} from 'lib/trix-range'
import {exponentialBackoff} from "./lib/exponential-backoff.js.erb"

// https://github.com/turbolinks/turbolinks/issues/272
// support remote get forms ... since turbolinks fails to do this properly

$(document).on('submit', 'form[method=get][data-turboize=true]', function (e) {
    e.preventDefault();
    let form = $(this);
    Turbolinks.visit(form.attr("action") + "?" + form.serialize());
});

import Bloodhound from 'corejs-typeahead'
let postcodes = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.whitespace,
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
        url: 'https://api.postcodes.io/postcodes/%QUERY/autocomplete',
        wildcard: '%QUERY',
        transform: function (response) {
            return response.result
        }
    }
});
let addresses = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.whitespace,
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
        prepare: function(query, settings) {
            settings.url = settings.url.replace('%QUERY',
                encodeURIComponent(query + ", " + document.getElementById("person_postcode").value));
            return settings;
        },
        url: 'https://autocomplete.geocoder.api.here.com/6.2/suggest.json?app_id=YZ6fev19iUuQihSomNgw&app_code=eqkhXrdWv3VAiEcVJwlJcw&query=%QUERY&country=GBR&language=en',
        transform: function (response) {
            return response.suggestions.filter(s => s.matchLevel === "houseNumber").map(function(s){
                let a = s.address;
                return a.houseNumber + " " + a.street;
            });
        }
    }
});

document.addEventListener("turbolinks:load", () => {
    $('input.btn').prop('disabled', false);

    let canvas = document.querySelector("#signatureCanvas");
    if (canvas) {
        let canvasBg = $("#signatureCanvasBg");
        let clearBtn = $("#clearCanvas");
        let sigBtn = $("#submitSignature");

        // This is based on a discrete cumulative density function model.
        // The original list would look like
        // {"0, 15, 85": 0.4, "0, 0, 139": 0.1,
        //             "0, 0, 0": 0.425, "102, 51, 153": 0.05, "139, 0, 0": 0.025}
        // However, here we use a cumulative version of the same list -
        // where the probabilities, the values, rather than adding to 1, get larger up to 1.
        let inkColours = {"0, 15, 85": 0.4, "0, 0, 139": 0.5,
            "0, 0, 0": 0.925, "102, 51, 153": 0.975, "139, 0, 0": 1};

        let selectedColour;
        let selectedProb = Math.random();
        for (const [key, value] of Object.entries(inkColours)) {
            if (value > selectedProb) {
                selectedColour = key;
                break;
            }
        }

        let signaturePad = new SignaturePad(canvas, {
            onEnd: function(){
                if (!signaturePad.isEmpty()){
                    canvasBg.fadeOut();
                    clearBtn.fadeIn();
                    sigBtn.prop("disabled", false);
                }
            },
            penColor: "rgb(" + selectedColour + ")"
        });
        function clearCanvas(){
            signaturePad.clear();
            canvasBg.fadeIn();
            clearBtn.fadeOut();
            sigBtn.prop("disabled", true);
        }
        function resizeCanvas() {
            let parentWidth = $(canvas).parent().outerWidth();
            canvas.setAttribute("width", parentWidth);
            canvasBg.width(parentWidth);
            clearCanvas();
        }
        resizeCanvas();
        window.onresize = resizeCanvas;
        clearBtn.click(clearCanvas);

        sigBtn.click(function(e){
            if (!signaturePad.isEmpty()){
                document.getElementById("sigInput").value = signaturePad.toDataURL("image/svg+xml");
                document.getElementById("submissionForm").submit();
            }
        });
    }

    if (!(Modernizr.input.required && Modernizr.formvalidation)) {
        hyperform(window);
    }

    let p_toggle = $('#privacy-toggle');
    let p_hidden = $('.privacy-hide');
    if(!p_toggle.data("opened")) {
        p_toggle.show();
        p_hidden.hide();
    }
    p_toggle.click(function(e){
        e.preventDefault();
        p_toggle.data("opened", true);
        p_toggle.fadeOut(200, function(){
            p_hidden.fadeIn(200, function(){
                if ('parentIFrame' in window) {
                    parentIFrame.size();
                }
            });
        });
    });

    function notAlreadyTypeaheaded() {
        return !$(this).parent().hasClass('twitter-typeahead');
    }

    $('.pc-typeahead').filter(notAlreadyTypeaheaded).typeahead({
        minLength: 2,
        highlight: true,
        hint: false
    }, {
        name: 'postcodes',
        source: postcodes
    });

    $('.addr-typeahead').filter(notAlreadyTypeaheaded).typeahead({
        minLength: 2,
        highlight: true,
        hint: true
    }, {
        name: 'addresses',
        source: addresses
    });

    let editors = $("trix-editor");
    function trixInputEvents(trix_inputs = $('.input-field-trix')){
        trix_inputs.off("change");
        trix_inputs.off("click");
        trix_inputs.click(function(e){
            $(e.target).removeClass("is-invalid");
        });
        trix_inputs.change(function(e){
            let ed = document.querySelector("trix-editor").editor;
            let val = e.target.value;
            ed.getDocument().getAttachmentById(parseInt($(e.target).parent().data("trix-id"))).setAttributes({
                allow_deletion: true
            });
            ed.setSelectedRange(getRangeOfElement(e.target));
            ed.deleteInDirection("forward");
            ed.insertLineBreak();
            ed.insertString(val);
            ed.insertLineBreak();
            trixInputEvents();
        });
    }
    editors.on("trix-change", function(){trixInputEvents()});
    editors.on("trix-initialize", function(){
        let ed = this.editor;
        let name_tags = findInDocument(ed, /%MEMBER_NAMES%(.*?)%END_MEMBER_NAMES%/ig);
        name_tags.forEach(function({captureGroup, range}) {
            ed.setSelectedRange(range);
            ed.deleteInDirection("forward");
            let attachment = new Trix.Attachment({ content: `<span class="name-sub">${captureGroup}</span>` });
            ed.insertAttachment(attachment);
        });
        ed.setSelectedRange(ed.getDocument().toString().length - 1);
        trixInputEvents();
    });
    editors.on("trix-file-accept", function(e){
        e.preventDefault();
    });
    editors.on("trix-attachment-remove", function(e){
        // This slightly ridiculous looking chain of object references eventually gives
        // the HTML content of the attachment. As tags that look like HTML input into the editor are
        // automatically sanitised by Trix, we can just check the string for the class name.
        let attachment = e.originalEvent.attachment.attachment;
        let attachmentAttrs = attachment.attributes.values;
        if(attachmentAttrs.content.includes("<textarea class=\"form-control input-field-trix\"") &&
            !(attachmentAttrs.allow_deletion)) {
            // Trix doesn't support preventDefault here, so here we
            // a) set an attribute to identify the element;
            // b) readd the same attachment again, just this time it doesn't have the attribute;
            // c) wait, by exponential backoff, for the nearly-gone element to go; and
            // d) update the input events for the 'new' old attachment.
            $("trix-editor figure[data-trix-id='" + attachment.id + "']").data("trix-nearly-gone", true);
            this.editor.insertAttachment(attachment);
            exponentialBackoff(function(){
                let sel = $("trix-editor figure[data-trix-id='" + attachment.id + "']");
                if(!sel.data("trix-nearly-gone")) {
                    trixInputEvents(sel.last());
                    return true;
                } else {
                    return false;
                }
            });
        }
    });

    $("#write_form").submit(function(e) {
        let trix_inputs = $('.input-field-trix');
        if (trix_inputs.length > 0) {
            e.preventDefault();
            trix_inputs.addClass("is-invalid");
            trix_inputs[0].scrollIntoView();
            return false
        } else {
            let consentWarning = $("#consent-warning");
            if (!(document.getElementById("person_privacy_1").checked) && !(consentWarning.data("warned") === true)) {
                e.preventDefault();
                consentWarning.fadeIn(200);
                consentWarning[0].scrollIntoView();
                consentWarning.data("warned", true);
                return false
            }
        }
    });
});