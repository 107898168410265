const Turbolinks = require("turbolinks");
let Iubenda = (function() {
    function Iubenda() {}

    Iubenda.load = function() {
        if (!window.ldspkFrame) {
            if (typeof Turbolinks !== "undefined" && Turbolinks.supported) {
                document.addEventListener("turbolinks:load", (function () {
                    return Iubenda.loadTag();
                }), true);
                document.addEventListener("turbolinks:before-cache", function() {
                    return Iubenda.deleteOldTags();
                }, true)
            } else {
                return Iubenda.loadTag();
            }
        }
    };

    Iubenda.loadTag = function() {
        Iubenda.deleteOldTags();
        return Iubenda.writeTag();
    };

    Iubenda.deleteOldTags = function() {
        let i, j, len, len1, ref, ref1, style, tag;
        ref = document.getElementsByTagName("script");
        for (i = 0, len = ref.length; i < len; i++) {
            tag = ref[i];
            Iubenda.deleteTag(tag);
        }
        ref1 = document.getElementsByTagName("style");
        for (j = 0, len1 = ref1.length; j < len1; j++) {
            style = ref1[j];
            if (Iubenda.deleteStyle(style)) {
                len1 = len1 - 1;
            }
        }
        return true;
    };

    Iubenda.writeTag = function() {
        let s, tag;
        s = document.createElement("script");
        s.src = Iubenda.url;
        tag = document.getElementsByTagName("script")[0];
        return tag.parentNode.insertBefore(s, tag);
    };

    Iubenda.deleteTag = function(tag) {
        if (typeof tag !== "undefined" && tag.src === Iubenda.url) {
            return tag.parentNode.removeChild(tag);
        }
    };

    Iubenda.deleteStyle = function(style) {
        if (style.innerHTML === "") {
            return style.parentNode.removeChild(style);
        } else {
            return false;
        }
    };

    Iubenda.url = "https://cdn.iubenda.com/iubenda.js";

    return Iubenda;
})();

Iubenda.load();

// ---
// generated by coffee-script 1.9.2
// from https://gist.github.com/jacquerie/11189438